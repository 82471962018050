import { StaticImage } from "gatsby-plugin-image"
import React from "react"

import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	desktopTitle: (
		<span>Автоматизация ресторана&nbsp;</span>
	),
	price: "от 2490 ₽/мес",
	subTitle:
		"Quick Resto — комплексная программа управления рестораном, ориентированная на скорость обслуживания и автоматизацию бизнес-процессов. Один зал с тремя столиками или три зала с отдельным баром — система автоматизация ресторана поможет оптимизировать обслуживание гостей, бухгалтерию, маркетинг, аналитику продаж, контроль склада, защиту от хищений и другие части бизнеса.",
	figure: (
		<StaticImage
			className={styles.image}
			src="./assets/restaurant-presentation.png"
			alt="автоматизация кафе"
			objectFit="contain"
			objectPosition="center"
			placeholder="blurred"
			quality={90}
		/>
	),
	cta: pagesLinks.registration.text,
	ctaLink: pagesLinks.registration,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования, с удобным интерфейсом. И две недели бесплатно.",
}
